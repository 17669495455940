<template>
  <div v-if="currentMandant" class="edit-form">
    <h4>Mandant {{ currentMandant.firmenbezeichnung.kurz }}</h4>
    <form>
      <div class="form-group">
        <label for="mdts">Mandantennummer Steuerberater (MDTS)</label>
        <input type="text"
               class="form-control"
               id="mdts"
               v-model="currentMandant.mdts"
        />
      </div>
      <div class="form-group">
        <label for="firmenbezeichnung">Firmenbezeichnung Voll</label>
        <input type="text"
               class="form-control"
               id="firmenbezeichnung"
               v-model="currentMandant.firmenbezeichnung.voll"
        />
      </div>
      <div class="form-group">
        <label for="firmenbezeichnungkurz">Firmenbezeichnung Kurz</label>
        <input type="text"
               class="form-control"
               id="firmenbezeichnungkurz"
               v-model="currentMandant.firmenbezeichnung.kurz"
        />
      </div>
      <div class="form-group">
        <label for="fnzusatz">Firmenzusatz</label>
        <input type="text"
               class="form-control"
               id="fnzusatz"
               v-model="currentMandant.firmenbezeichnung.zusatz"
        />
      </div>
      <h5>Inhaber</h5>
      <div class="form-group">
        <label for="inhabertitel">Titel</label>
        <input type="text"
               class="form-control"
               id="inhabertitel"
               v-model="currentMandant.inhaber.titel"
        />
      </div>
      <div class="form-group">
        <label for="inhabervorname">Vorname</label>
        <input type="text"
               class="form-control"
               id="inhabervorname"
               v-model="currentMandant.inhaber.vorname"
        />
      </div>
      <div class="form-group">
        <label for="inhabername">Nachname</label>
        <input type="text"
               class="form-control"
               id="inhabername"
               v-model="currentMandant.inhaber.name"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrertitel">Geschäftsführer Titel</label>
        <input type="text"
               class="form-control"
               id="gfuehrertitel"
               v-model="currentMandant.gFuehrer.titel"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrervorname">Geschäftsführer Vorname</label>
        <input type="text"
               class="form-control"
               id="gfuehrervorname"
               v-model="currentMandant.gFuehrer.vorname"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrername">Geschäftsführer voller Name</label>
        <input type="text"
               class="form-control"
               id="gfuehrername"
               v-model="currentMandant.gFuehrer.name"
        />
      </div>
      <h5>Adresse</h5>
      <div class="form-group">
        <label for="strasse">Straße</label>
        <input type="text"
               class="form-control"
               id="strasse"
               v-model="currentMandant.adresse.strasse"
        />
      </div>
      <div class="form-group">
        <label for="plz">Postleitzahl</label>
        <input type="text"
               class="form-control"
               id="plz"
               v-model="currentMandant.adresse.plz"
        />
      </div>
      <div class="form-group">
        <label for="ort">Ort</label>
        <input type="text"
               class="form-control"
               id="ort"
               v-model="currentMandant.adresse.ort"
        />
      </div>
      <div class="form-group">
        <label for="web">Internetseite</label>
        <input type="text"
               class="form-control"
               id="web"
               v-model="currentMandant.kontakt.web"
        />
      </div>
      <div class="form-group">
        <label for="telefon">Telefon</label>
        <input type="text"
               class="form-control"
               id="telefon"
               v-model="currentMandant.kontakt.telefon"
        />
      </div>
      <div class="form-group">
        <label for="email">E-Mail Adresse</label>
        <input type="text"
               class="form-control"
               id="email"
               v-model="currentMandant.kontakt.email"
        />
      </div>
    </form>

    <button class="badge badge-primary mr-2"
            v-if="currentMandant.active"
            @click="updateActivated(false)"
    >
      Deaktivieren
    </button>
    <button v-else class="badge badge-primary mr-2"
            @click="updateActivated(true)"
    >
      Aktivieren
    </button>

    <button class="badge badge-danger mr-2"
            @click="deleteMandant"
    >
      Löschen
    </button>

    <button type="submit" class="badge badge-success"
            @click="updateMandant"
    >
      Aktualisieren
    </button>
    <p>{{ message }}</p>
  </div>

  <div v-else>
    <p>Wählen Sie einen Mandanten aus.</p>
  </div>
</template>

<script>
import MandantService from "../services/mandant.service";

export default {
  name: "mandant",
  data() {
    return {
      currentMandant: null,
      message: ''
    };
  },
  methods: {
    getMandant(id) {
      MandantService.get(id)
          .then(response => {
            this.currentMandant = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    updateActivated(status) {
      this.currentMandant.active = status;
      let data = this.currentMandant;

      MandantService.update(this.currentMandant._id, data)
          .then(response => {
            console.log(response.data);
            this.currentMandant.active = status;
            this.message = 'Der Status wurde erfolgreich aktualisiert!';
          })
          .catch(e => {
            console.log(e);
          });
    },

    updateMandant() {
      MandantService.update(this.currentMandant._id, this.currentMandant)
          .then(response => {
            console.log(response.data);
            this.message = 'Der Mandant wurde erfolgreich aktualisiert!';
          })
          .catch(e => {
            console.log(e);
          });
    },

    deleteMandant() {
      MandantService.delete(this.currentMandant._id)
          .then(response => {
            console.log(response.data);
            this.$router.push({ name: "mandants" });
          })
          .catch(e => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.message = '';
    this.getMandant(this.$route.params._id);
  }
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>